import { canUseDOM } from 'exenv';
import React, { useRef, useCallback, useEffect } from 'react';
import BEMHelper from 'react-bem-helper';
import { bool, func, string } from 'prop-types';
import { Carousel as Caroucssel } from 'caroucssel';
import { useTranslation } from 'react-i18next';

import { usePolyfill } from '../../../hooks/usePolyfill';
import { childrenType } from '../../../types/children';
import './carousel.scss';

const bem = new BEMHelper({
  name: 'carousel',
});

const useAutoResize = (element, carousel, props) => {
  const { autoResize, onScroll = () => undefined } = props;

  // The function that contains the resize logic: It searches for the the highes
  // currently visible item and resizes the container element accordingly.
  const resize = useCallback(() => {
    if (!carousel.current) {
      return;
    }

    const { index, items } = carousel.current;
    const heights = (index || []).map((at) => items[at].getBoundingClientRect().height);
    const height = Math.max(...heights);
    const parent = element.current?.parentElement;

    if (!parent) {
      return;
    }

    parent.style.height = `${height}px`;
  }, [element, carousel]);

  // The resize observer is required for carousels that are initially hidden,
  // for example elements in an accordion. Those elements will be calculated with
  // a height of 0px because they are not attached to the visual DOM (display: none).
  // To detect if those elemements are visible, we use the resize observer.
  const ObserverClassRef = useRef(canUseDOM ? window.ResizeObserver : null);
  const observerRef = useRef(null);
  const ResizeObserver = ObserverClassRef.current;

  // Polyfill resize observer if not present
  usePolyfill(
    () => 'ResizeObserver' in window,
    () => import(/* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill/dist/ResizeObserver.global'),
    () => { ObserverClassRef.current = window.ResizeObserver; },
  );

  // This configures the resize observer. It need to wait until all conditions
  // are met, to initialize the resize observer (loading polyfill, element ref
  // available, etc...)
  useEffect(() => {
    if (!autoResize || !element.current || !ResizeObserver || observerRef.current) {
      return;
    }

    const instance = new ResizeObserver(() => resize());
    instance.observe(element.current);
    observerRef.current = instance;

    resize();
  }, [element, autoResize, resize, observerRef, ResizeObserver]);

  // Scroll handler that should be used as callback when the carousel is
  // scrolling. Passes the arguments from the carousel instance to the given
  // `onScroll` prop...
  const onScrollFn = useCallback((...args) => {
    if (autoResize) {
      resize();
    }

    onScroll(...args);
  }, [autoResize, resize, onScroll]);

  return { onScroll: onScrollFn };
};

const useCarousel = (element, carousel, { onScroll }) => {
  // To work properly, the caroucssel library is using the "native" scroll-behaviour.
  // If a browser doesn't support this, we are loading a polyfill for that...
  usePolyfill(
    () => 'scrollBehavior' in document.documentElement.style,
    () => import(/* webpackChunkName: "scroll-behavior-polyfill" */'scroll-behavior-polyfill'),
  );

  const { t } = useTranslation();
  useEffect(() => {
    if (carousel.current || !element.current) {
      return () => undefined;
    }

    carousel.current = new Caroucssel(element.current, {
      index: [0],
      hasButtons: true,
      hasPagination: true,
      hasScrollbars: false,
      scrollbarsMaskClassName: bem('mask').className,
      buttonClassName: '',
      buttonNext: {
        className: bem('button', 'next').className,
        label: t('Next page'),
        title: t('Go to next page'),
      },
      buttonPrevious: {
        className: bem('button', 'prev').className,
        label: t('Previous page'),
        title: t('Go to previous page'),
      },
      paginationClassName: bem('pagination').className,
      paginationLabel: ({ index }) => t('{{ index }}. page', { index: index + 1 }),
      paginationTitle: ({ index }) => t('Jump to {{ index }}. page', { index: index + 1 }),
      onScroll,
    });
    return () => carousel.current.destroy();
  }, []);
};

export const Carousel = ({
  tagName: TagName,
  className,
  children,
  ...props
}) => {
  const { autoResize } = props;
  const element = useRef(null);
  const carousel = useRef(null);
  const { onScroll } = useAutoResize(element, carousel, props);
  useCarousel(element, carousel, { ...props, onScroll });

  return (
    <div {...bem(null, { 'uses-autoresize': autoResize }, className)}>
      <TagName {...bem('container', null, `${className}__container`)} ref={element}>
        {children}
      </TagName>
    </div>
  );
};

Carousel.propTypes = {
  tagName: string,
  children: childrenType.isRequired,
  className: string,
  autoResize: bool,
  onScroll: func,
};

Carousel.defaultProps = {
  tagName: 'div',
  className: undefined,
  autoResize: false,
  onScroll: () => undefined,
};
